import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@mui/material";
import { patientSelector } from "../../../store/slice/patient.slice";
import { debounce } from "../../../utilities/common";
import {
  getDoctorContactList,
  sendReportToDoctorOrLab,
  sendReportToDoctorToAnyone,
} from "../../../store/actions/records.action";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { authSelector } from "../../../store/slice/auth.slice";

const errorMessages = {
  RESTRICTED_FILE: (
    <div style={{ color: "#CC8552" }}>
      You can only share restricted files to doctor and lab.
    </div>
  ),
  INVALID_EMAIL_PHONE_NO: (
    <div style={{ color: "#CC8552" }}>
      Please enter valid email or phone number.
    </div>
  ),
  ALREADY_SHARED: (
    <div style={{ color: "#0099CC" }}>
      You have already shared with this recipient.
    </div>
  ),
  AVAILABLE_NUMBER: (
    <div style={{ color: "#0099CC" }}>This profile is available.</div>
  ),
  SELECT_CONTACT: (
    <div style={{ color: "#CC8552" }}>Please select recipient’s from list.</div>
  ),
  USER: (
    <div style={{ color: "#CC8552" }}>You can't share report to yourself.</div>
  ),
};

const MobileEmailVerification = ({
  reportId,
  setRecipientInfo,
  recipientInfo,
  recordType,
  recordInfo,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [contactInfo, setContactInfo] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const dispatch = useDispatch();
  const { selectedPatientId } = useSelector(patientSelector);
  const [error, setError] = useState("");
  const [showConfirmationBox, setShowConformationBox] = useState(false);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [isSendAnyone, setIsSendAnyone] = useState(false);
  const state = useSelector(authSelector);
  const navigate = useNavigate();

  const fetchContactRecord = async (query = "") => {
    if (query === "") {
      setIsDisabledNext(true);
      return;
    }
    const payload = {
      search: query,
    };
    try {
      const action = await dispatch(getDoctorContactList({ payload: payload }));
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.result || [];
        setContactInfo(responseData);
        setIsDisabledNext(false);
      } else {
        console.error("Failed to fetch contact list:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch contact list:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedFetchContactRecord(e.target.value);
    setContactInfo([]);
    setError("");
    setIsSendAnyone(false);
    setSelectedContact(null);
    setShowConformationBox(false);
    setIsDisabledNext(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedContact(suggestion);
    setContactInfo([]);
    setIsDisabledNext(false);
    setError("");
  };

  const debouncedFetchContactRecord = useCallback(
    debounce(fetchContactRecord, 300),
    []
  );

  const handleNext = () => {
    if (!selectedContact && contactInfo.length) {
      setError(errorMessages.SELECT_CONTACT);
      setIsDisabledNext(true);
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobilePattern = /^(\+91\s?)?[0-9]{10}$/;
    const isEmailValid = emailPattern.test(searchQuery);
    const isMobileValid = mobilePattern.test(searchQuery);

    if (!selectedContact && !isEmailValid && !isMobileValid) {
      setError(errorMessages.INVALID_EMAIL_PHONE_NO);
      return;
    }
    if (!selectedContact) {
      if (recordInfo?.status === "RESTRICTED") {
        setError(errorMessages.RESTRICTED_FILE);
        return;
      }
      setIsSendAnyone(true);
      setIsDisabledNext(true);
      setShowConformationBox(true);
      return;
    }
    if (
      selectedContact?.fhir_resource === "Practitioner" &&
      ((selectedContact?.email &&
        state?.userDetails?.email === selectedContact.email) ||
        (selectedContact?.phone &&
          state?.userDetails?.phone_number === selectedContact.phone))
    ) {
      setError(errorMessages.USER);
      setIsDisabledNext(true);
      return;
    }
    if (recipientInfo.some((item) => item.grantee_id === selectedContact.id)) {
      setError(errorMessages.ALREADY_SHARED);
      return;
    }
    setShowConformationBox(true);
    setError(errorMessages.AVAILABLE_NUMBER);
    setIsDisabledNext(true);
  };
  const renderCardAnyone = () => (
    <ListItem
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        background: "#FFF",
        border: "2px solid #7aba57",
        display: "flex",
        justifyContent: "space-between",
        minWidth: { xs: 200, sm: 300 },
      }}
    >
      <Box display="flex" alignItems="center">
        <ListItemAvatar>
          <Avatar src="/broken-image.jpg" sx={{ width: 40, height: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="body2">{searchQuery}</Typography>}
        />
      </Box>
      <IconButton
        edge="end"
        aria-label="delete"
        sx={{ color: "red" }}
        onClick={(e) => handleRemoveContact(e)}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );
  const handleConfirm = async (e) => {
    e.stopPropagation();
    const payload = {
      patient_id: selectedPatientId,
      grantee_id: selectedContact.id,
      grantee_type: selectedContact.fhir_resource,
      report_type: recordType,
      resource_id: reportId,
      ...(recordType === "scan" && {
        scan_info: {
          modality: recordInfo?.modality,
          study_date: recordInfo?.study_date,
          location: recordInfo?.location,
          description: recordInfo?.description,
        },
      }),
    };
    try {
      const action = await dispatch(
        sendReportToDoctorOrLab({ payload: payload })
      );
      const response = action.payload;
      if (response && response.status === "success") {
        setRecipientInfo([
          ...recipientInfo,
          {
            grantee_id: selectedContact?.id,
            email: selectedContact?.email,
            name: selectedContact?.name,
            phone_number: selectedContact?.phone,
          },
        ]);
        setShowConformationBox(false);
        toast.success(
          "Record has been shared with the recipient with permanent access.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          },
          500
        );
        setTimeout(() => {
          navigate(-1);
        }, 500);
      } else {
        console.error("Failed to share report:", action?.error);
        if (response) {
          if (response === "Grantee already has the requested consent") {
            setError(
              <div style={{ color: "#CC8552" }}>
                This recipient already has access to this file.
              </div>
            );
            toast.error("This recipient already has access to this file.", {
              position: "bottom-right",
              autoClose: 1000,
            });
            return;
          }
          toast.error(response, {
            position: "bottom-right",
            autoClose: 1000,
          });
        } else {
          toast.error("Error occurred while sharing the record.", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Failed to share report: 11", error?.response);
      if (error?.response?.data?.message) {
        setShowConformationBox(false);
        setError(
          <div style={{ color: "#CC8552" }}>{error.response.data.message}</div>
        );
        toast.error(error.response.data.message, {
          position: "bottom-right",
          autoClose: 1000,
        });
      } else {
        toast.error("Error occurred while sharing the record.", {
          position: "bottom-right",
          autoClose: 1000,
        });
      }
    }
  };

  const handleAnyone = async (e) => {
    e.stopPropagation();
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = emailPattern.test(searchQuery);
    let contact;
    if (!isEmailValid) {
      contact = !searchQuery.startsWith("+91")
        ? `+91${searchQuery.trim()}`
        : searchQuery.trim();
    } else {
      contact = searchQuery.trim();
    }
    const payload = {
      patient_id: selectedPatientId,
      contact_id: contact,
      report_type: recordType,
      resource_id: reportId,
      ...(recordType === "scan" && {
        scan_info: {
          modality: recordInfo?.modality,
          study_date: recordInfo?.study_date,
          location: recordInfo?.location,
          description: recordInfo?.description,
        },
      }),
    };
    try {
      const action = await dispatch(
        sendReportToDoctorToAnyone({ payload: payload })
      );
      const response = action.payload;
      if (response && response.status === "success") {
        setRecipientInfo([
          ...recipientInfo,
          {
            email: searchQuery,
          },
        ]);
        setShowConformationBox(false);
        toast.success(
          "Record has been shared with the recipient with 1 year access.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          },
          500
        );
        setTimeout(() => {
          navigate(-1);
        }, 500);
      } else {
        console.error("Failed to share report:", action?.error);
        if (response) {
          if (response === "Grantee already has the requested consent") {
            setError(
              <div style={{ color: "#CC8552" }}>
                This recipient already has access to this file.
              </div>
            );
            toast.error("This recipient already has access to this file.", {
              position: "bottom-right",
              autoClose: 1000,
            });
            return;
          }
          toast.error(response, {
            position: "bottom-right",
            autoClose: 1000,
          });
          setError(<div style={{ color: "#CC8552" }}>{response}</div>);
        } else {
          toast.error("Error occurred while sharing the record.", {
            position: "bottom-right",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Failed to share report: 11", error?.response);
      if (error?.response?.data?.message) {
        setShowConformationBox(false);
        setError(
          <div style={{ color: "#CC8552" }}>{error.response.data.message}</div>
        );
        toast.error(error.response.data.message, {
          position: "bottom-right",
          autoClose: 1000,
        });
      } else {
        toast.error("Error occurred while sharing the record.", {
          position: "bottom-right",
          autoClose: 1000,
        });
      }
    }
  };

  const handleRemoveContact = (e) => {
    e.stopPropagation();
    setSelectedContact(null);
    setSearchQuery("");
    setIsDisabledNext(true);
    setError("");
    setIsSendAnyone(false);
    setContactInfo([]);
  };

  const renderCard = (contact) => (
    <ListItem
      sx={{
        cursor: "pointer",
        borderRadius: 2,
        background: "#FFF",
        border: "2px solid #7aba57",
        display: "flex",
        justifyContent: "space-between",
        minWidth: { xs: 200, sm: 300 },
      }}
    >
      <Box display="flex" alignItems="center">
        <ListItemAvatar>
          <Avatar src="/broken-image.jpg" sx={{ width: 40, height: 40 }} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" variant="body2" color="#0099CC">
              {contact?.name}
            </Typography>
          }
          secondary={
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                {contact?.phone}
              </Typography>
              <Typography variant="body2">{contact?.email}</Typography>
            </>
          }
        />
      </Box>
      <IconButton
        edge="end"
        aria-label="delete"
        sx={{ color: "red" }}
        onClick={(e) => handleRemoveContact(e)}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );

  return (
    <>
      <Box sx={{ gap: 1 }} mb={2}>
        <Box mb={1} variant="body2">
          Enter new recipient’s E-mail or Mobile Number.
        </Box>
        <Box display="flex" gap={1}>
          <Box display="flex" flexDirection="column">
            {!selectedContact && !isSendAnyone && (
              <>
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  sx={{
                    background: "#FFF",
                    minWidth: { xs: 200, sm: 300, md: 350 },
                    height: "40px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                    },
                    marginRight: 1,
                  }}
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <Typography sx={{ fontSize: "12px", marginTop: 1 }}>
                  Please include country code for mobile number.
                </Typography>
              </>
            )}
            {isSendAnyone && renderCardAnyone()}
            {selectedContact && renderCard(selectedContact)}
            {contactInfo.length > 0 && (
              <List
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  background: "#FFF",
                  borderRadius: 2,
                  mr: 1,
                }}
              >
                {contactInfo.map((contact, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleSuggestionClick(contact)}
                    sx={{
                      cursor: "pointer",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src="/broken-image.jpg"
                        sx={{ width: 40, height: 40 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="#0099CC"
                        >
                          {contact?.name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {contact?.phone}
                          </Typography>
                          <Typography variant="body2">
                            {contact?.email}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "#FFF" }}
              onClick={handleNext}
              disabled={isDisabledNext}
            >
              Next
            </Button>
          </Box>
        </Box>
        {error && (
          <Box mt={1} mb={2}>
            {error}
          </Box>
        )}
      </Box>
      {showConfirmationBox && selectedContact && (
        <FileShareBox
          selectedContact={selectedContact}
          handleConfirm={handleConfirm}
        />
      )}
      {showConfirmationBox && isSendAnyone && searchQuery && (
        <FileShareAnyoneBox
          searchQuery={searchQuery}
          handleAnyone={handleAnyone}
        />
      )}
    </>
  );
};

const FileShareAnyoneBox = ({ searchQuery, handleAnyone }) => {
  return (
    <Box
      p={2}
      mt={2}
      mb={2}
      sx={{ background: "#D7EFF7", maxWidth: 250, borderRadius: 2 }}
    >
      <Typography
        mb={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 240,
        }}
        title={searchQuery}
      >
        {searchQuery}
      </Typography>
      <Typography sx={{ fontSize: "0.8rem", color: "#808080" }} mb={2}>
        Are you sure about sharing?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ color: "#FFF" }}
        py={3}
        onClick={handleAnyone}
      >
        Confirm
      </Button>
    </Box>
  );
};

const FileShareBox = ({ selectedContact, handleConfirm }) => {
  return (
    <Box
      p={2}
      mt={2}
      mb={2}
      sx={{ background: "#D7EFF7", maxWidth: 250, borderRadius: 2 }}
    >
      <Typography mb={1}>
        {selectedContact?.name ||
          selectedContact?.email ||
          selectedContact?.phone}
      </Typography>
      <Typography sx={{ fontSize: "0.8rem", color: "#808080" }} mb={2}>
        Are you sure about sharing?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ color: "#FFF" }}
        py={3}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </Box>
  );
};

export default MobileEmailVerification;
